<template>
    <div class="tab">
        <button id="tab-Comercial" class="tablinks" @click="(event) => openTable(event, 'Comercial')">Paseo
            Veranda</button>
        <button id="tab-Vivienda" class="tablinks" @click="(event) => openTable(event, 'Vivienda')">Quinta
            Amelia</button>
        <div class="expanded"></div>
    </div>
    <div class="tabcontent Comercial">
        <br>
        <br>
    </div>

    <div class="tabcontent Vivienda">
        <br>
        <br>
    </div>

    <TablaViviendas />
    <TablaComercial />
</template>

<script>
import TablaViviendas from './TablaViviendas';
import TablaComercial from './TablaComercial';
import { mapState, mapActions } from 'vuex'
export default {
    components: {
        TablaViviendas, TablaComercial
    },
    computed: {
        ...mapState(['currentTab'])
    },
    methods: {
        ...mapActions(['setCurrentTab']),
        openTable(evt, zoneName) {
            // Declare all variables
            var i, tabcontent, tablinks, sectionContent;

            // Get all elements with class="tabcontent" and hide them
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }

            // Get all elements with class="tablinks" and remove the class "active"
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            // document.getElementById(zoneName).style.display = "block";
            sectionContent = document.getElementsByClassName(zoneName);
            for (i = 0; i < sectionContent.length; i++) {
                sectionContent[i].style.display = "block";
            }

            evt.currentTarget.className += " active";

            this.setCurrentTab(`tab-${zoneName}`);
        }

    },
    mounted() {
        document.getElementById(this.currentTab).click();
    }
}
</script>

<style>
@media print {
    .filter-by {
        display: none;
    }
}

/* Style the tab */
.tab {
    display: flex;
    overflow: hidden;
    /* border: 1px solid #ccc;*/
    background-color: #F2F7F5;
}

/* Style the buttons that are used to open the tab content */
.tab button {
    background-color: inherit;
    float: left;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #ccc;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-weight: 600;
}

/* Change background color of buttons on hover */
.tab button:hover {
    color: #005F3C;
    transition: 0.3s;
}

/* Create an active/current tablink class */
.tab button.active {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    background-color: #F2F7F5;
    color: #005F3C;
}


.expanded {
    flex-grow: 1;
    align-items: flex-end;
    border-bottom: 1px solid #ccc;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-radius: 0 0 5px 5px;
}

.tabcontent {
    animation: fadeEffect 1s;
    /* Fading effect takes 1 second */
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.custom-table {
    border: none;
}
</style>