<template>
    <div class="tabcontent Vivienda custom-table">
        <table class="table" ref="testHtml">
            <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Manzana</th>
                    <th scope="col">Lote</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Acción</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td>
                        <div class="btn-group">
                            <div class="dropdown-center filter-by">
                                <button class="btn btn-light dropdown-toggle btn-square" type="button"
                                    id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <span v-if="selectedType === 'all'">Todas</span>
                                    <span v-else>{{ selectedType }}</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <button class="dropdown-item" type="button" @click="search('all')">TODAS</button>
                                    <button v-for="item in manzanas" :key="item" class="dropdown-item" type="button"
                                        @click="search(`${item}`)">{{ item.toUpperCase() }}</button>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div class="btn-group">
                            <div class="dropdown-center filter-by">
                                <button class="btn btn-light dropdown-toggle btn-square" type="button"
                                    id="dropdownState" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <span v-if="selectedState === 'all'">Todos</span>
                                    <span v-else>
                                        <span :class="getSelectedType">
                                            &nbsp&nbsp;</span>
                                        {{ selectedState.toUpperCase() }}</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownState">
                                    <button class="dropdown-item" type="button"
                                        @click="searchByState('all')">TODOS</button>
                                    <button v-for="item in states" :key="item" class="dropdown-item" type="button"
                                        @click="searchByState(`${item}`)">
                                        <span
                                            :class="{ 'bg-danger badge rounded': item == 'vendido', 'bg-warning badge  rounded': item == 'reservado', 'bg-success badge rounded': item }">
                                            &nbsp&nbsp;</span>
                                        {{ item.toUpperCase() }}</button>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td></td>
                </tr>

                <tr v-if="searchResult.length == 0">
                    <td class="text-center" colspan="5">
                        Ningún resultado coincide con los filtros de búsqueda.
                    </td>
                </tr>

                <tr v-for="item in searchResult" :key="item.id">
                    <th scope="row">{{ searchResult.indexOf(item) + 1 }}</th>
                    <td>{{ item.manzana }}</td>
                    <td>{{ item.lote }}</td>
                    <td>
                        <span
                            :class="{ 'bg-danger badge rounded': item.estado == 'vendido', 'bg-warning badge  rounded': item.estado == 'reservado', 'bg-success badge rounded': item.estado }">
                            &nbsp&nbsp;</span>
                        {{ item.estado.toUpperCase() }}
                    </td>
                    <td>
                        <router-link class="btn btn-secondary c-btn-bg ml-2 btn-sm" :to="{
                            name: 'Editar',
                            params: {
                                id: item.id,
                                source: 'viviendas',
                            }
                        }">
                            Editar
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            selectedType: 'all',
            selectedState: 'all',
        }

    },
    computed: {
        ...mapState(['tareas', 'searchResult', 'manzanas', 'states', 'filterState']),
        getSelectedType: function () {
            return {
                'bg-danger badge rounded': this.selectedState == 'vendido',
                'bg-warning badge rounded': this.selectedState == 'reservado',
                'bg-success badge rounded': this.selectedState == 'disponible'
            }
        },
    },
    methods: {
        ...mapActions(['setFilter',]),
        search(manzana) {
            this.selectedType = manzana;
            this.setFilter({ 'manzana': manzana, 'estado': this.selectedState })
        },
        searchByState(state) {
            this.selectedState = state;
            this.setFilter({ 'manzana': this.selectedType, 'estado': state });
        },
    }
}
</script>