<template>

  <div class="container">
    <!-- TODO Título del proyecto debe ser dinámico -->
    <h2 v-if="currentTab === 'tab-Vivienda'" class="pt-2">Proyecto - Quinta Amelia</h2>
    <h2 v-else class="pt-2">Proyecto - Paseo Veranda</h2>
    <h4 class="mb-4">Anden Inversiones</h4>
    <ListaHotspots />
  </div>
</template>

<script>

import Input from '../components/Input'
import ListaHotspots from '../components/ListaHotspots'
import { mapState, mapActions } from 'vuex'
const shortid = require('shortid');

export default {
  name: 'Home',
  components: {
    Input, ListaHotspots
  },
  data() {
    return {
      tarea: {
        id: '',
        manzana: '',
        lote: '',
        estado: '',
      }
    }
  },
  computed: {
    ...mapState(['currentTab']),
  },
  methods: {
    ...mapActions(['setHostpot', 'cargarLocalStorage', 'setCurrentRoute', 'getUserData', 'cargarZonaComercial', 'loadCurrentTab']),
    procesarFormulario() {

      if (this.tarea.nombre.trim() === "") {
        return
      }


      // generar id
      this.tarea.id = shortid.generate()
      

      // limpiar datos
      this.tarea = {
        id: '',
        nombre: '',
        categorias: [],
        estado: '',
        numero: 0
      }
    },

  },
  created() {
    this.cargarLocalStorage();
    this.cargarZonaComercial();
    this.setCurrentRoute('home');
    this.getUserData();
    this.loadCurrentTab();
  }

}
</script>

<style scoped>
.list-item {
  margin-top: 50px;
}

.card {
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
  width: 400px;
  padding: 20px 30px;
  margin-bottom: 30px;
}
</style>
