<template>
  <div v-if="showNavbar">
    <Navbar />
  </div>
  <div class="container-center pb-5">
    <router-view />

  </div>


</template>

<script>
import { mapActions, mapState } from 'vuex'
import Navbar from './components/Navbar'
import ThemeButton from './components/ThemeButton.vue'
export default {
  components: {
    Navbar, ThemeButton
  },
  methods: {
    ...mapActions(['cargarLocalStorage', 'cargarZonaComercial'])
  },
  created() {
    this.cargarLocalStorage();
    this.cargarZonaComercial();
  },
  computed: {
    ...mapState(['currentRoute']),
    showNavbar: function () {
      return this.currentRoute != 'ingreso'
    }
  }
}
</script>
  

<style>
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto';
}

:root {
  --background-color-primary: #FFFFFF;
  --btn-background-color: #121212;
  --background-color-secondary: #FFFFFF;
  --accent-color: #FFFFFF;
  --text-primary-color: #222;
  --element-size: 4rem;
}

:root.dark-theme {
  --background-color-primary: #121212;
  --btn-background-color: #FFFFFF;
  --background-color-secondary: #2d2d30;
  --accent-color: #3f3f3f;
  --text-primary-color: #121212;
}

p,
h1,
label,
h2,
th,
td {
  color: var(--text-primary-color);
}

.container-center {
  background-color: var(--background-color-primary);
  height: 100vh;
  width: 100vw;
}

.c-bg {
  background-color: var(--background-color-primary);
}

.c-btn-bg {
  background-color: var(--btn-background-color);
  color: var(--accent-color);
  border: none;
}

.logotipo > path {
  height: 50.px;
  color: red;
  fill: red;
  
}

.c-bg-white {
  background-color: #FFFFFF;
}

.c-color {
  color: var(--text-primary-color);
}

.card {
  padding: 2rem 4rem;
  height: 200px;
  width: 300px;
  text-align: center;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  background-color: var(--background-color-secondary);
}

.btn-square {
  border-radius: 5;
  border: 1px solid #CCC;
}
</style>

